import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hello world</h1>
        <p>Here goes the Blueconic DXP script</p>
        <div>
          <button>BlueConic Button 1</button>
          <button>BlueConic Button 2</button>
          <button>BlueConic Button 3</button>
          <button>BlueConic Button 4</button>
        </div>
      </header>
    </div>
  );
}

export default App;
